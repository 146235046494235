import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/about": [6],
		"/about/beyond/desk": [7],
		"/about/science": [8],
		"/about/science/presentations": [9],
		"/about/science/pubs": [10],
		"/contact": [11],
		"/login": [~12],
		"/login/nounImages": [13],
		"/portfolio": [14],
		"/portfolio/RSSfeed": [15,[2]],
		"/portfolio/RSSfeed/feed/[title]": [~16,[2]],
		"/portfolio/allowanceTracker": [17],
		"/portfolio/allowanceTracker/parent": [18],
		"/portfolio/csvUpdater": [19],
		"/portfolio/hubspotExample": [20],
		"/portfolio/inventoryExample": [21],
		"/portfolio/nounGenderGame": [22],
		"/portfolio/pick-a-restaurant": [23],
		"/portfolio/spotifyPlaylistMix": [~24,[3]],
		"/portfolio/spotifyPlaylistMix/lastTracks": [25,[3,4]],
		"/portfolio/spotifyPlaylistMix/login": [26,[3]],
		"/portfolio/spotifyPlaylistMix/login/connecting": [27,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';